import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components"
import { device } from '../components/Device'
import LogoImage from '../images/Logo.png'

const MenuContainer = styled.div`
    background-color: blue;
    height: 64px;
    background: #FFFFFF;
    box-shadow: 0px 1px 0px #F2F2F2;
`

const LogoContainer = styled.div`
    float: left;
    margin-top: 10px;
    margin-left: 32px;
`

const ToggleInput = styled.input`
    display: none;
`

const CustomLink = styled(Link)`
    float: left;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #3E3E3E;
    text-decoration: none;
    margin-left: 4px;
    padding: 23px;
    padding-left: 13px;

    @media ${device.menuBreaker} {
            display: block;
            float: none;
            border-bottom: 1px solid #3E3E3E;
            padding: 18px;
            margin-left: 0;
    }
`

const LinksContainer = styled.nav`
    float: right;
    :last-child {
        margin-right: 32px;
    }

    @media ${device.menuBreaker} {
        display: none;
        margin-top: 64px;
        text-align: center;

        ${ToggleInput}:checked + & {
            display: block;
            position: absolute;
            width: 100%;
            left: 0;
            background: white;
            border-top: 1px solid #3E3E3E;
        }
    }
`

const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;
    float: left;

    @media ${device.menuBreaker} {
        float: none;
        display: block; 
        ${CustomLink} {
            background-color: #F2F2F2;
        }
        ${CustomLink}:first-child {
            background-color: #FFF;
        }
    }
`

const Triangle = styled.div`
    position: absolute;
    top: -7px;
    left: 72px;
    width: 13px;
    height: 8px;
    background: white;
    box-shadow: 0px 4px 30px rgba(62, 62, 62, 0.06);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    @media ${device.menuBreaker} {
        display: none;
    }
`

const DropdownContent = styled.div`
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: #FFF;
    min-width: 160px;
    box-shadow: 0px 4px 30px rgba(62, 62, 62, 0.06);
    border-radius: 4px;
    z-index: 1;
    top: 78px;
    left: -20px;
    transition: opacity 0.3s, visibility 0.3s;

    :nth-child(2) {
        padding-top: 30px;
    }

    ${DropdownContainer}:hover & {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s, visibility 0.3s;
    }

    @media ${device.menuBreaker} {
        visibility: visible;
        opacity: 1;
        position: static;
        :nth-child(2) {
            padding-top: 0px;
        }
        box-shadow: 0px 0px 0px rgba(62, 62, 62, 0.06);
    }
`

const ArrowIcon = styled.i`
    border: solid #3E3E3E;
    border-width: 0 1.4px 1.4px 0;
    display: inline-block;
    padding: 3px;  transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-left: 6px;
    margin-bottom: 2px;
`


const Toggle = styled.label`
    display: none;
    float: right;
    font-size: 30px;
    color: #3E3E3E;
    margin-top: 16px;
    margin-right: 32px;
    @media ${device.menuBreaker} {
        display: block;
        cursor: pointer;
    }
`
const DropdownLink = styled(CustomLink)`
    margin: 0;
    padding: 0;
    padding-left: 24px;
    padding-bottom: 24px;
    width: 100%;
    display: block;
    @media ${device.menuBreaker} {
        padding: 10px 0;
    }
`

const MainMenu = () => {
    const queryResponse = useStaticQuery(
        graphql`
          query 
          {
            wpMenu(id: {eq: "dGVybToy"}) {
                menuItems {
                              nodes {
                                  label
                      path
                      parentId
                      id
                      childItems {
                                      nodes {
                                          label
                          path
                          id
                        }
                      }
                    }
                }
            }
          }
          
           
        `
    )

    const menuItems = queryResponse.wpMenu.menuItems.nodes
    return (<MenuContainer>
        <LogoContainer>
            <Link to="/">
                <img src={LogoImage} />
            </Link>
        </LogoContainer>
        <Toggle htmlFor="toggle">&#9776;</Toggle>
        <ToggleInput type="checkbox" id="toggle" />
        <LinksContainer>
            
            {menuItems.map(item => {
                if (item.childItems.nodes.length > 0) {
                    return (
                        <DropdownContainer key={item.id}>
                            <CustomLink to="#">{item.label}<ArrowIcon /></CustomLink>
                            <DropdownContent>
                                <Triangle />
                                {item.childItems.nodes.map(child => <DropdownLink key={child.id} to={`${child.path}`}>{child.label}</DropdownLink>)}
                            </DropdownContent>
                        </DropdownContainer>
                    )
                } else if(!item.parentId) {
                    return <CustomLink key={item.id} to={`${item.path}`}>{item.label}</CustomLink>
                }
            })}
        </LinksContainer>
    </MenuContainer>
    )
}

export default MainMenu
