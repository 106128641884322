import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import MainMenu from './MainMenu'
import GlobalStyle from './GlobalStyles'
import Footer from './Footer'

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <MainMenu />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout